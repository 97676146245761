import React from 'react';
import { Link } from 'react-router-dom';
import styles from './LandingPage.module.scss';

type LandingPageProps = {}

export default function LandingPage(props: LandingPageProps): React.ReactElement {
    return (
        <div className={styles.container}>
            <section className={styles.feature}>
                Ready to make a poll?
            </section>
            {/* <CreatePollForm /> */}
            <Link className={styles.freePoll} to='/poll/create'>Create</Link>
        </div>
    )
}