import React from 'react';
import ReactGA from 'react-ga';
import './App.css';
import { Switch, Route, useHistory } from 'react-router-dom';
import Header from './components/Header/Header';
// import Footer from './components/Footer/Footer';
import LandingPage from './components/LandingPage/LandingPage';
import About from './components/AboutPage/About';
import Contact from './components/ContactPage/Contact';
import CreatePollForm from './components/Polls/CreatePollForm';
import RegisterPage from './components/RegisterPage';
import PollPage from './components/Polls/PollPage';

// This is for google Analytics
function Analytics() {
  const history = useHistory()
  history.listen((location, action) => {
    ReactGA.set({page: location.pathname})
    ReactGA.pageview(location.pathname)
  })
  return <> </>
}


function App() {
  return (
    <div>
      <Header test="test" />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/auth/register" component={RegisterPage} />
        <Route exact path="/poll/create" component={CreatePollForm} />
        {/* <Route exact path="/testform" component={TestForm} /> */}
        <Route exact path="/poll/:id" component={PollPage} />
      </Switch>
      {/* <Footer /> */}
        <Analytics />
    </div>
  );
}

// function TestForm() {
//   const returnedFormData = {
//     formName: "Test form",
//     formDescription: "This is a form to be a \"proof of concept\" for our data model around forms and rendering forms returned from the backend. Not styling or interactive functionality. Simply just rendering the form questions with the correct settings/options. Not hooked up to backend at all.",
//     formQuestions: [
//       {
//         questionText: "Explain how this will work?",
//         questionType: "textInput",
//         optional: false,
//         minLength: 5,
//         maxLength: 300
//       },
//       {
//         questionText: "How many days until we can launch an MVP?",
//         questionType: "numberInput",
//         optional: false,
//         minLength: 1,
//         maxLength: 30
//       },
//       {
//         questionText: "Test Text input no min or max length provided by form creator",
//         questionType: "textInput",
//         optional: false
//       },
//       {
//         questionText: "Test Number input no min or max value provided by form creator",
//         questionType: "numberInput",
//         optional: false
//       },
//       {
//         questionText: "What is the MVP feature?",
//         questionType: "select",
//         optional: false,
//         questionOptions: [
//           "Form Creation",
//           "Polls",
//           "Quiz Creation"
//         ]
//       },
//       {
//         questionText: "What features should we not have at all?",
//         questionType: "multipleChoice",
//         optional: false,
//         maxSelections: 3,
//         questionOptions: [
//           "Form Creation",
//           "Polls",
//           "Quiz Creation"
//         ]
//       }
//     ]
//   }

//   const createTextInputFormQuestion = (formQuestion: any): React.ReactElement => {
//     return (
//       <div key={formQuestion.questionText}>
//         <label htmlFor={formQuestion.questionText}>{formQuestion.questionText}</label>
//         <br />
//         <input
//           name={formQuestion.questionText}
//           type="text"
//           required={!formQuestion.optional}
//           minLength={formQuestion.minLength != null ? formQuestion.minLength : 0}
//           maxLength={formQuestion.maxLength ? formQuestion.maxLength : 500}
//         />
//         <br />
//         <br />
//       </div>
//     )
//   }

//   const createNumberInputFormQuestion = (formQuestion: any): React.ReactElement => {
//     return (
//       <div key={formQuestion.questionText}>
//         <label htmlFor={formQuestion.questionText}>{formQuestion.questionText}</label>
//         <br />
//         <input
//           name={formQuestion.questionText}
//           type="number"
//           required={!formQuestion.optional}
//           min={formQuestion.minLength != null ? formQuestion.minLength : Number.MIN_SAFE_INTEGER}
//           max={formQuestion.maxLength ? formQuestion.maxLength : Number.MAX_SAFE_INTEGER}
//         />
//         <br />
//         <br />
//       </div>
//     )
//   }

//   const createSelectFormQuestion = (formQuestion: any): React.ReactElement => {
//     return (
//       <div key={formQuestion.questionText}>
//         <label>{formQuestion.questionText}</label>
//         {formQuestion.questionOptions.map((option: any) => (
//           <div key={`${formQuestion.questionText}-${option}`}>
//             <label htmlFor={option}>{option}
//               <input
//                 name={formQuestion.questionText}
//                 value={option}
//                 required={!formQuestion.optional}
//                 type="radio"
//               />
//             </label>
//           </div>
//         ))}
//         <br /><br />
//       </div >
//     )
//   }

//   const createMultipleChoiceFormQuestion = (formQuestion: any): React.ReactElement => {
//     return (
//       <div key={formQuestion.questionText}>
//         <label>{formQuestion.questionText}</label>
//         {formQuestion.questionOptions.map((option: any) => (
//           <div key={`${formQuestion.questionText}-${option}`}>
//             <label htmlFor={option}>{option}
//               <input
//                 name={formQuestion.questionText}
//                 value={`${formQuestion.questionText}-${option}`}
//                 type="checkbox"
//               />
//             </label>
//           </div>
//         ))}
//         <br /><br />
//       </div >
//     )
//   }

//   console.log(`Here is the data that will be returned from the backend, that will be used to render the form for a user: `, { formData: returnedFormData })
//   return (
//     <div style={{ border: "1px solid red" }}>
//       <h1>{returnedFormData.formName}</h1>
//       <h2>{returnedFormData.formDescription}</h2>
//       <form>
//         {returnedFormData.formQuestions.map((formQuestion: any) => {
//           switch (formQuestion.questionType) {
//             case "textInput":
//               return createTextInputFormQuestion(formQuestion);
//             case "numberInput":
//               return createNumberInputFormQuestion(formQuestion);
//             case "select":
//               return createSelectFormQuestion(formQuestion);
//             case "multipleChoice":
//               return createMultipleChoiceFormQuestion(formQuestion);
//             default:
//               return <></>
//           }
//         })}
//         <button>Submit</button>
//       </form>
//     </div>
//   )
// }

export default App;
