import React, { useState } from "react";
import axios from "axios";
import style from "./CreatePollForm.module.scss";
import { useHistory } from "react-router-dom";

type NewPollForm = {
    formName: string;
    multipleChoice: boolean;
    answer: string;
    answers: string[];
};

export default function CreatePollForm() {

    let history = useHistory()

    const [form, setForm] = useState<NewPollForm>({
        formName: "",
        multipleChoice: false,
        answer: "",
        answers: []
    });

    // All Handlers

    const createPoll = (e: any) => {
        e.preventDefault();
        console.log(form)
        axios.post(`https://formmon.herokuapp.com/api/Poll`, {
            form: {
                ...form,
                formType: 'Poll'
            },
            answerOptions: form.answers
        })
            .then(res => {
                console.log(res.data)
                history.push(`/Poll/${res.data.form.id}`)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleChange = (e: any) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    };

    const handleChecked = (e: any) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    };

    const addAnswer = (e: any) => {
        e.preventDefault()
        const newAnswer: string = form.answer
        setForm({
            ...form,
            answer: "",
            answers: [...form.answers, newAnswer]
        })
    };

    const deleteAnswer = (index: number) => {
        const newAnswers = form.answers.filter((a, i) => i !== index)
        setForm({
            ...form,
            answers: newAnswers
        })
    }

    return (
        <form className={style.form}>
            <h2 className={style.h2}>Quick Poll Maker</h2>
            <div className={style.formBody}>
                <label>Question: </label>
                <input className={style.question}
                    onChange={handleChange}
                    type="text"
                    name="formName"
                    placeholder="Question..."
                    value={form.formName}
                    required
                />
                <div className={style.multipleChoice}>
                    <label>Multiple Choice? </label>
                    <input
                        onChange={handleChecked}
                        type="checkbox"
                        checked={form.multipleChoice}
                        name="multipleChoice"
                    />
                </div>
                <label>Answers: </label>
                <div className={style.answerContainer}>
                    <input
                        onChange={handleChange}
                        type="text"
                        name="answer"
                        placeholder="Answers go here!"
                        value={form.answer}
                        required
                    />
                    <button className={style.addAnswer} type={undefined} onClick={addAnswer}>Add</button>
                </div>

                {
                    form.answers.length > 0 && <div className={style.listContainer}>
                        {form.answers.map((a, i) => <div>
                            <div className={style.answerList}>{a}</div>
                            <div className={style.deleteX} onClick={() => deleteAnswer(i)}><span>Delete</span></div>
                        </div>)}
                    </div>
                }

                <button className={style.create} onClick={createPoll}>Create Poll</button>
            </div>
        </form>
    )
}