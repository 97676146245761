import React from 'react';
import styles from './RegisterPage.module.scss';

type RegisterPageProps = {}

function RegisterPage(props: RegisterPageProps): React.ReactElement {
    return (
        <div className={styles.container}>
            <p>Register page</p>
        </div>
    )
}

export default RegisterPage;