import React from 'react';
import { Link } from 'react-router-dom';
import style from './Header.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import Nav from '../Navigation/Nav';

type HeaderProps = {
    test: string
}

function Header({ test }: HeaderProps): React.ReactElement {

    return (
        <header className={style.header}>
            {/* <div className={style.reg}>
                <button className={style.register}>Register</button>
            </div> */}
            <FontAwesomeIcon className={style.logo} icon={faChartSimple} />
            <Link className={style.formmon} to='/'>Formmon</Link>
            <Nav />
            {/* <div className={style.logButtons}>
                <div className={style.logout}>Logout</div>
                <button className={style.login}>Login</button>
            </div> */}
        </header>
    )
}

export default Header;