import React from "react";
import style from "./About.module.scss"


export default function About() {
    
    return (
        <div className={style.container}>
            <h1>About Formmon</h1>
            <div className={style.content1}>
                This project is starting out with a simple feature that allows a user to make a poll and send the link to others to cast their votes without the use of an account. Over the course of the year, we will be adding more features as well as polishing them as we go.
            </div>
            <h2>Future of Formmon</h2>
            <div className={style.content2}>
                Future updates to the site are already on the backlog and will be implemented once they have been built and tested. The speed in which these updates come to the user solely depend on the time we have to spare as this is a side projecct in our free time. We plan on allowing users to make an account and save their polls as well as add other features such as multi-question surveys and other forms. 

                For any questions about Formmon, please visit our <a href="/contact">Contact</a> page.
            </div>
        </div>
    )
}