import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
// import style from "./PollPage.moudule.scss";
import { GetPollResponse } from "./Poll.interface";
import PollVote from "./PollVote";
import axios from "axios";
import PollResults from "./PollResults";

type PageParams = {
    id: string;
}

export default function PollPage() {
    
    const [poll, setPoll] = useState<GetPollResponse>();

    const [isVoting, setIsVoting] = useState<boolean>(true);

    const setVoting = (voting: boolean) => {
        setIsVoting(voting)
    }

    let {id} = useParams<PageParams>();

    useEffect(() => {
        axios.get(`https://formmon.herokuapp.com/api/Poll/${id}`)
            .then(res => {
                console.log(res)
                if (localStorage.getItem(id)) setIsVoting(false);
                setPoll(res.data)
            })
            .catch(err => {
                console.log(err)
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(!poll) {
        return <div>No poll</div>
    }

    return (
        <div>
            {(isVoting) && <PollVote poll={poll} setVoting={setVoting} setPoll={setPoll} />}
            {(!isVoting) && <PollResults poll={poll} />}
        </div>
    )
}