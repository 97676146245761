import React from "react";
import style from "./PollResults.module.scss"
import { GetPollResponse } from "./Poll.interface";

type PollResultsProps = {
    poll: GetPollResponse;
}

function copyPollLink() {
    const link = window.location.href;
    navigator.clipboard.writeText(link)
}

export default function PollResults(props: PollResultsProps) {
    console.log(props.poll);
    return (
        <div className={style.container}>
            <h1 className={style.h1}>{props.poll.form.formName}</h1>
            <h2 className={style.h2}>Results</h2>
            {props.poll.answerOptions.map(o => {
                return (
                    <div className={style.data}>
                        <span className={style.answers}>{o.answer}</span> <span className={style.votes}>{o.votes}</span>
                    </div>
                )
            })}
            <button className={style.share} onClick={copyPollLink}>Share</button>
        </div>
    )
}