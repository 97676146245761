import React from 'react';
import style from './Nav.module.scss';
import { NavLink } from 'react-router-dom';



export default function Nav() {
    return (
        <nav>
            <NavLink exact activeClassName={style.active} to='/'>Home</NavLink>
            {/* <NavLink activeClassName={style.active} to='/plans'>Plans</NavLink> */}
            <NavLink activeClassName={style.active} to='/about'>About</NavLink>
            <NavLink activeClassName={style.active} to='/contact'>Contact</NavLink>
            {/* <NavLink activeClassName={style.active} to='/teachers'>Teachers</NavLink> */}
        </nav>
    )
}