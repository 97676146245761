import React from "react";
import style from "./Contact.module.scss";

export default function Contact() {

    return (
        <div className={style.container}>
            <h1>Contact Us</h1>
            <div className={style.content}>
                For any questions about the project please reach out to Ashton on <a href='https://www.linkedin.com/in/ashtonthedev/'>LinkedIn</a>
            </div>
        </div>
    )
}