import React, {useState} from "react";
import { useParams } from "react-router-dom";
import axios from "axios"
import { GetPollResponse, PollOption } from "./Poll.interface";
import style from "./PollVote.module.scss"


type PollVoteProps = {
    poll: GetPollResponse;
    setVoting: (voting: boolean) => void;
    setPoll: (poll: GetPollResponse) => void;
};

type PageParams = {
    id: string;
}

export default function PollVote(props: PollVoteProps) {
    
    const params = useParams<PageParams>()

    const [vote, setVote] = useState<string[]>([]);

    const submitVote = (e: any) => {
        e.preventDefault();
        axios.post(`https://formmon.herokuapp.com/api/Poll/${params.id}/Vote`, vote)
            .then(res => {
                localStorage.setItem(params.id, 'true')
                let updatedResults = props.poll.answerOptions.map(r => {
                    if (vote.includes(r.id)) {
                        r.votes++
                    }
                        return r
                });
                props.setPoll({
                    ...props.poll,
                    answerOptions: updatedResults
                })
                props.setVoting(false)
            })
            .catch(err => console.log(err))
    }

    const handleVote = (a: string) => {
        if (props.poll.form.multipleChoice) {
            if (vote?.includes(a)) {
                setVote(vote.filter(s => s !== a))
            } else {
                setVote([...vote, a])
            }
        } else {
            setVote([a])
        }
    }
    console.log(vote)
    return (
        <div className={style.container}>
            <h1>{props.poll.form.formName}</h1>

            {/* Array of answers. Using classes dependent on multiple choice being T/F */}
            {props.poll.answerOptions.map((a: PollOption) => {
                return (
                    <div className={`${style.option} ${vote.includes(a.id) ? style.selected : ''}`} onClick={() => handleVote(a.id)}>{a.answer}</div>
                )
            })}

            <button className={style.vote} onClick={submitVote}>Vote</button>
        </div>
    )
};